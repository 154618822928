/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI lib comoponents
import { Col, Container, Row } from 'react-grid-system';
import Image from '../../../shared/Image';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

import appleLogo from '../../../images/homepage/hero/apple-black.svg';
import androidLogo from '../../../images/homepage/hero/android-black.svg';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Hero() {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */
  const { t } = useTranslation();

  const {
    title,
    description,
    storesCTA: { apple, android },
  } = t('hero', {
    returnObjects: true,
  });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /* ***************************** RENDER HELPERS ***************************** */
  function StoresCTA() {
    return (
      <div className="stores flex flex-wrap flex-center-m mt-30-m gap-30">
        <button
          type="button"
          className="flex flex-center store-container bg-white px-20 px-xl-30 py-15 br6"
        >
          <img src={appleLogo} alt="apple store" />
          <p className="mb-0 f-lg-14 ms-18 text-start text-dark-blue">
            {apple.text}
            <br />
            <span className="f-lg-16 f-xl-22 bold">{apple.name}</span>
          </p>
        </button>
        <button
          type="button"
          className="flex flex-center store-container bg-white px-20 px-xl-30 py-15 br6 m-0-m"
        >
          <img src={androidLogo} alt="apple store" />
          <p className="mb-0 f-lg-14 ms-18 text-start text-dark-blue">
            {android.text}
            <br />
            <span className="f-lg-16 f-xl-22 bold">{android.name}</span>
          </p>
        </button>
      </div>
    );
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <section className="hero">
      <Container className="py-64">
        <Row justify="between" align="center" gutterWidth={60}>
          <Col lg={6}>
            <h1 className="f-xxl-66 f-xl-48 f-lg-36 text-white ff-domine title">
              {title}
            </h1>
            <p className="f-xxl-24 f-lg-18 text-white light">{description}</p>
            <div className="d-none d-lg-flex">
              <StoresCTA />
            </div>
          </Col>
          <Col lg={6} className="mt-30-m">
            <Image src="homepage/hero/hero-img" alt="Hero Image" />
            <div className="d-lg-none d-flex flex-center-m mt-30">
              <StoresCTA />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
