/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';

// UI lib components
import Hero from '../page-components/Index/Hero';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';

// styles
import '../page-styles/index.scss';

const Showcase = React.lazy(() => import('../page-components/Index/Showcase'));
const AppCta = React.lazy(() => import('../page-components/Index/AppCta'));

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function IndexPage() {
  /* ********************************** HOOKS ********************************* */

  // Localization

  const isSSR = typeof window === 'undefined';

  /* ******************************** RENDERING ******************************* */
  return (
    <>
      <Seo title="Accueil" />
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <PageLayout>
            <Hero />
            <Showcase />
            <AppCta />
          </PageLayout>
        </React.Suspense>
      )}
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage;
